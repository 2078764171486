.show-for-medium-up {
  display: inherit !important; }

.hide-for-medium-up {
  display: none !important; }

.row {
  max-width: none !important; }

/*GRID*/
.row {
  padding-left: 0.234375em !important;
  padding-right: 0.234375em !important; }

.row .row {
  width: auto !important;
  margin-left: -0.46875em !important;
  margin-right: -0.46875em !important; }

.column,
.columns {
  padding-left: 0.234375em !important;
  padding-right: 0.234375em !important; }

/*@media only screen {*/
.small-push-0 {
  position: relative !important;
  left: 0% !important;
  right: auto !important; }

.small-pull-0 {
  position: relative !important;
  right: 0% !important;
  left: auto !important; }

.small-push-1 {
  position: relative !important;
  left: 8.33333% !important;
  right: auto !important; }

.small-pull-1 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important; }

.small-push-2 {
  position: relative !important;
  left: 16.66667% !important;
  right: auto !important; }

.small-pull-2 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important; }

.small-push-3 {
  position: relative !important;
  left: 25% !important;
  right: auto !important; }

.small-pull-3 {
  position: relative !important;
  right: 25% !important;
  left: auto !important; }

.small-push-4 {
  position: relative !important;
  left: 33.33333% !important;
  right: auto !important; }

.small-pull-4 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important; }

.small-push-5 {
  position: relative !important;
  left: 41.66667% !important;
  right: auto !important; }

.small-pull-5 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important; }

.small-push-6 {
  position: relative !important;
  left: 50% !important;
  right: auto !important; }

.small-pull-6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important; }

.small-push-7 {
  position: relative !important;
  left: 58.33333% !important;
  right: auto !important; }

.small-pull-7 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important; }

.small-push-8 {
  position: relative !important;
  left: 66.66667% !important;
  right: auto !important; }

.small-pull-8 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important; }

.small-push-9 {
  position: relative !important;
  left: 75% !important;
  right: auto !important; }

.small-pull-9 {
  position: relative !important;
  right: 75% !important;
  left: auto !important; }

.small-push-10 {
  position: relative !important;
  left: 83.33333% !important;
  right: auto !important; }

.small-pull-10 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important; }

.small-push-11 {
  position: relative !important;
  left: 91.66667% !important;
  right: auto !important; }

.small-pull-11 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important; }

.column,
.columns {
  position: relative !important;
  float: left !important; }

.small-1 {
  width: 8.33333% !important; }

.small-2 {
  width: 16.66667% !important; }

.small-3 {
  width: 25% !important; }

.small-4 {
  width: 33.33333% !important; }

.small-5 {
  width: 41.66667% !important; }

.small-6 {
  width: 50% !important; }

.small-7 {
  width: 58.33333% !important; }

.small-8 {
  width: 66.66667% !important; }

.small-9 {
  width: 75% !important; }

.small-10 {
  width: 83.33333% !important; }

.small-11 {
  width: 91.66667% !important; }

.small-12 {
  width: 100% !important; }

.small-offset-0 {
  margin-left: 0% !important; }

.small-offset-1 {
  margin-left: 8.33333% !important; }

.small-offset-2 {
  margin-left: 16.66667% !important; }

.small-offset-3 {
  margin-left: 25% !important; }

.small-offset-4 {
  margin-left: 33.33333% !important; }

.small-offset-5 {
  margin-left: 41.66667% !important; }

.small-offset-6 {
  margin-left: 50% !important; }

.small-offset-7 {
  margin-left: 58.33333% !important; }

.small-offset-8 {
  margin-left: 66.66667% !important; }

.small-offset-9 {
  margin-left: 75% !important; }

.small-offset-10 {
  margin-left: 83.33333% !important; }

.small-offset-11 {
  margin-left: 91.66667% !important; }

.small-reset-order {
  margin-left: 0 !important;
  margin-right: 0 !important;
  left: auto !important;
  right: auto !important;
  float: left !important; }

.column.small-centered,
.columns.small-centered {
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important; }

.column.small-uncentered,
.columns.small-uncentered {
  margin-left: 0 !important;
  margin-right: 0 !important;
  float: left !important; }

.column.small-centered:last-child,
.columns.small-centered:last-child {
  float: none !important; }

.column.small-uncentered:last-child,
.columns.small-uncentered:last-child {
  float: left !important; }

.column.small-uncentered.opposite,
.columns.small-uncentered.opposite {
  float: right !important; }

/*}*/
/*@media only screen and (min-width: 49.063em) {*/
.medium-push-0 {
  position: relative !important;
  left: 0% !important;
  right: auto !important; }

.medium-pull-0 {
  position: relative !important;
  right: 0% !important;
  left: auto !important; }

.medium-push-1 {
  position: relative !important;
  left: 8.33333% !important;
  right: auto !important; }

.medium-pull-1 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important; }

.medium-push-2 {
  position: relative !important;
  left: 16.66667% !important;
  right: auto !important; }

.medium-pull-2 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important; }

.medium-push-3 {
  position: relative !important;
  left: 25% !important;
  right: auto !important; }

.medium-pull-3 {
  position: relative !important;
  right: 25% !important;
  left: auto !important; }

.medium-push-4 {
  position: relative !important;
  left: 33.33333% !important;
  right: auto !important; }

.medium-pull-4 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important; }

.medium-push-5 {
  position: relative !important;
  left: 41.66667% !important;
  right: auto !important; }

.medium-pull-5 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important; }

.medium-push-6 {
  position: relative !important;
  left: 50% !important;
  right: auto !important; }

.medium-pull-6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important; }

.medium-push-7 {
  position: relative !important;
  left: 58.33333% !important;
  right: auto !important; }

.medium-pull-7 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important; }

.medium-push-8 {
  position: relative !important;
  left: 66.66667% !important;
  right: auto !important; }

.medium-pull-8 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important; }

.medium-push-9 {
  position: relative !important;
  left: 75% !important;
  right: auto !important; }

.medium-pull-9 {
  position: relative !important;
  right: 75% !important;
  left: auto !important; }

.medium-push-10 {
  position: relative !important;
  left: 83.33333% !important;
  right: auto !important; }

.medium-pull-10 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important; }

.medium-push-11 {
  position: relative !important;
  left: 91.66667% !important;
  right: auto !important; }

.medium-pull-11 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important; }

.column,
.columns {
  position: relative !important;
  float: left !important; }

.medium-1 {
  width: 8.33333% !important; }

.medium-2 {
  width: 16.66667% !important; }

.medium-3 {
  width: 25% !important; }

.medium-4 {
  width: 33.33333% !important; }

.medium-5 {
  width: 41.66667% !important; }

.medium-6 {
  width: 50% !important; }

.medium-7 {
  width: 58.33333% !important; }

.medium-8 {
  width: 66.66667% !important; }

.medium-9 {
  width: 75% !important; }

.medium-10 {
  width: 83.33333% !important; }

.medium-11 {
  width: 91.66667% !important; }

.medium-12 {
  width: 100% !important; }

.medium-offset-0 {
  margin-left: 0% !important; }

.medium-offset-1 {
  margin-left: 8.33333% !important; }

.medium-offset-2 {
  margin-left: 16.66667% !important; }

.medium-offset-3 {
  margin-left: 25% !important; }

.medium-offset-4 {
  margin-left: 33.33333% !important; }

.medium-offset-5 {
  margin-left: 41.66667% !important; }

.medium-offset-6 {
  margin-left: 50% !important; }

.medium-offset-7 {
  margin-left: 58.33333% !important; }

.medium-offset-8 {
  margin-left: 66.66667% !important; }

.medium-offset-9 {
  margin-left: 75% !important; }

.medium-offset-10 {
  margin-left: 83.33333% !important; }

.medium-offset-11 {
  margin-left: 91.66667% !important; }

.medium-reset-order {
  margin-left: 0 !important;
  margin-right: 0 !important;
  left: auto !important;
  right: auto !important;
  float: left !important; }

.column.medium-centered,
.columns.medium-centered {
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important; }

.column.medium-uncentered,
.columns.medium-uncentered {
  margin-left: 0 !important;
  margin-right: 0 !important;
  float: left !important; }

.column.medium-centered:last-child,
.columns.medium-centered:last-child {
  float: none !important; }

.column.medium-uncentered:last-child,
.columns.medium-uncentered:last-child {
  float: left !important; }

.column.medium-uncentered.opposite,
.columns.medium-uncentered.opposite {
  float: right !important; }

.push-0 {
  position: relative !important;
  left: 0% !important;
  right: auto !important; }

.pull-0 {
  position: relative !important;
  right: 0% !important;
  left: auto !important; }

.push-1 {
  position: relative !important;
  left: 8.33333% !important;
  right: auto !important; }

.pull-1 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important; }

.push-2 {
  position: relative !important;
  left: 16.66667% !important;
  right: auto !important; }

.pull-2 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important; }

.push-3 {
  position: relative !important;
  left: 25% !important;
  right: auto !important; }

.pull-3 {
  position: relative !important;
  right: 25% !important;
  left: auto !important; }

.push-4 {
  position: relative !important;
  left: 33.33333% !important;
  right: auto !important; }

.pull-4 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important; }

.push-5 {
  position: relative !important;
  left: 41.66667% !important;
  right: auto !important; }

.pull-5 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important; }

.push-6 {
  position: relative !important;
  left: 50% !important;
  right: auto !important; }

.pull-6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important; }

.push-7 {
  position: relative !important;
  left: 58.33333% !important;
  right: auto !important; }

.pull-7 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important; }

.push-8 {
  position: relative !important;
  left: 66.66667% !important;
  right: auto !important; }

.pull-8 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important; }

.push-9 {
  position: relative !important;
  left: 75% !important;
  right: auto !important; }

.pull-9 {
  position: relative !important;
  right: 75% !important;
  left: auto !important; }

.push-10 {
  position: relative !important;
  left: 83.33333% !important;
  right: auto !important; }

.pull-10 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important; }

.push-11 {
  position: relative !important;
  left: 91.66667% !important;
  right: auto !important; }

.pull-11 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important; }

/*}*/
/*@media only screen and (min-width: 64.063em) {*/
.large-push-0 {
  position: relative !important;
  left: 0% !important;
  right: auto !important; }

.large-pull-0 {
  position: relative !important;
  right: 0% !important;
  left: auto !important; }

.large-push-1 {
  position: relative !important;
  left: 8.33333% !important;
  right: auto !important; }

.large-pull-1 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important; }

.large-push-2 {
  position: relative !important;
  left: 16.66667% !important;
  right: auto !important; }

.large-pull-2 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important; }

.large-push-3 {
  position: relative !important;
  left: 25% !important;
  right: auto !important; }

.large-pull-3 {
  position: relative !important;
  right: 25% !important;
  left: auto !important; }

.large-push-4 {
  position: relative !important;
  left: 33.33333% !important;
  right: auto !important; }

.large-pull-4 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important; }

.large-push-5 {
  position: relative !important;
  left: 41.66667% !important;
  right: auto !important; }

.large-pull-5 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important; }

.large-push-6 {
  position: relative !important;
  left: 50% !important;
  right: auto !important; }

.large-pull-6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important; }

.large-push-7 {
  position: relative !important;
  left: 58.33333% !important;
  right: auto !important; }

.large-pull-7 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important; }

.large-push-8 {
  position: relative !important;
  left: 66.66667% !important;
  right: auto !important; }

.large-pull-8 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important; }

.large-push-9 {
  position: relative !important;
  left: 75% !important;
  right: auto !important; }

.large-pull-9 {
  position: relative !important;
  right: 75% !important;
  left: auto !important; }

.large-push-10 {
  position: relative !important;
  left: 83.33333% !important;
  right: auto !important; }

.large-pull-10 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important; }

.large-push-11 {
  position: relative !important;
  left: 91.66667% !important;
  right: auto !important; }

.large-pull-11 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important; }

.column,
.columns {
  position: relative !important;
  float: left !important; }

.large-1 {
  width: 8.33333% !important; }

.large-2 {
  width: 16.66667% !important; }

.large-3 {
  width: 25% !important; }

.large-4 {
  width: 33.33333% !important; }

.large-5 {
  width: 41.66667% !important; }

.large-6 {
  width: 50% !important; }

.large-7 {
  width: 58.33333% !important; }

.large-8 {
  width: 66.66667% !important; }

.large-9 {
  width: 75% !important; }

.large-10 {
  width: 83.33333% !important; }

.large-11 {
  width: 91.66667% !important; }

.large-12 {
  width: 100% !important; }

.large-offset-0 {
  margin-left: 0% !important; }

.large-offset-1 {
  margin-left: 8.33333% !important; }

.large-offset-2 {
  margin-left: 16.66667% !important; }

.large-offset-3 {
  margin-left: 25% !important; }

.large-offset-4 {
  margin-left: 33.33333% !important; }

.large-offset-5 {
  margin-left: 41.66667% !important; }

.large-offset-6 {
  margin-left: 50% !important; }

.large-offset-7 {
  margin-left: 58.33333% !important; }

.large-offset-8 {
  margin-left: 66.66667% !important; }

.large-offset-9 {
  margin-left: 75% !important; }

.large-offset-10 {
  margin-left: 83.33333% !important; }

.large-offset-11 {
  margin-left: 91.66667% !important; }

.large-reset-order {
  margin-left: 0 !important;
  margin-right: 0 !important;
  left: auto !important;
  right: auto !important;
  float: left !important; }

.column.large-centered,
.columns.large-centered {
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important; }

.column.large-uncentered,
.columns.large-uncentered {
  margin-left: 0 !important;
  margin-right: 0 !important;
  float: left !important; }

.column.large-centered:last-child,
.columns.large-centered:last-child {
  float: none !important; }

.column.large-uncentered:last-child,
.columns.large-uncentered:last-child {
  float: left !important; }

.column.large-uncentered.opposite,
.columns.large-uncentered.opposite {
  float: right !important; }

.push-0 {
  position: relative !important;
  left: 0% !important;
  right: auto !important; }

.pull-0 {
  position: relative !important;
  right: 0% !important;
  left: auto !important; }

.push-1 {
  position: relative !important;
  left: 8.33333% !important;
  right: auto !important; }

.pull-1 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important; }

.push-2 {
  position: relative !important;
  left: 16.66667% !important;
  right: auto !important; }

.pull-2 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important; }

.push-3 {
  position: relative !important;
  left: 25% !important;
  right: auto !important; }

.pull-3 {
  position: relative !important;
  right: 25% !important;
  left: auto !important; }

.push-4 {
  position: relative !important;
  left: 33.33333% !important;
  right: auto !important; }

.pull-4 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important; }

.push-5 {
  position: relative !important;
  left: 41.66667% !important;
  right: auto !important; }

.pull-5 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important; }

.push-6 {
  position: relative !important;
  left: 50% !important;
  right: auto !important; }

.pull-6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important; }

.push-7 {
  position: relative !important;
  left: 58.33333% !important;
  right: auto !important; }

.pull-7 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important; }

.push-8 {
  position: relative !important;
  left: 66.66667% !important;
  right: auto !important; }

.pull-8 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important; }

.push-9 {
  position: relative !important;
  left: 75% !important;
  right: auto !important; }

.pull-9 {
  position: relative !important;
  right: 75% !important;
  left: auto !important; }

.push-10 {
  position: relative !important;
  left: 83.33333% !important;
  right: auto !important; }

.pull-10 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important; }

.push-11 {
  position: relative !important;
  left: 91.66667% !important;
  right: auto !important; }

.pull-11 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important; }

/*}*/
